<template>
<div>
    <v-menu bottom origin="center center" transition="scale-transition" min-width="400" max-height="500" tile>

        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" :loading="loading" :color="$vuetify.theme.dark ? 'white' : 'third'">
                <v-icon>fi fi-rr-bell</v-icon>
            </v-btn>
        </template>

        <div v-if="items == ''  ">
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <h3>لا توجد إشعارات</h3>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </div>

        <!-- <div v-if="items != '' "> -->
          <v-list v-for="(item, i) in items" :key="i" >
              <v-list-item @click="seen(item.id)">
                  <v-list-item-title>
                    <v-alert border="right" tile colored-border="" :color="item.seen ? 'transparent' : 'primary'" dense>
                          <v-row align="center" class="ma-1">
                            <h5 class="primary--text">{{ item.sender.fullName }}</h5><v-spacer/>
                            <h6 dir="ltr" class="grey--text ">{{ new Date(item.issue).toLocaleString()  }}</h6>
                          </v-row>
                      <h3 class="mt-2">{{ item.title }}</h3>
                      <h4 class="grey--text mt-2">{{ item.body }}</h4>
                    </v-alert>
                  </v-list-item-title>
              </v-list-item>
              <v-divider/>
              
          </v-list>
          <v-card>
            <infinite-loading @infinite="infiniteHandler"></infinite-loading>
          </v-card>
          
        <!-- </div> -->
    </v-menu>
</div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
export default {
    components: {
      InfiniteLoading,
    },
    data: () => ({
      loading: false,
      filter:{
        take: 100,
        skip: 0
      },
        items: [
          // {
          //   id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          //   sender: {
          //       id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          //       fullName: "أمير هيثم عبدالأمير",
          //       photo: null
          //   },
          //   receiver: {
          //       id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          //       fullName: "string",
          //       photo: ''
          //   },
          //   issue: "2021-05-30T15:13:02.993Z",
          //   title: "string string string string string string",
          //   body: "string",
          //   type: "string",
          //   typeId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          //   seen: false
          // },
        ],
    }),

    created(){
      this.getItems()
    },

    methods:{
      getItems(){
        this.loading = true
        this.$http.get(`Notifications?PageIndex=${this.filter.take}&PageSize=${this.filter.skip}&Seen=false`).then(res => {
          this.items = res.data.result
        }).catch(err => {
          console.log(err)
        }).finally(()=> this.loading = false)
      },

      seen(notificationId){
        this.$http.put(`Notification/${notificationId}`).then(res => {
          this.items.find(x => x.id == notificationId).seen = true
        }).catch(err => {
          console.log(err)
        })
      },

      infiniteHandler($state) {
        this.$http.get(`Notification?take=${this.filter.take+100}&skip=${this.filter.take}&deleted=false&receiver=true`).then(res => {
          this.items.push(res.data.result)  
        }).catch(err => {
          console.log(err)
        })
      }
    }
}
</script>
